* {
    /*border: 1px solid red;*/
}

#gameboard {
    display: grid;
    grid-template-rows: minmax(0, 1fr) 0 repeat(5, minmax(0, 1fr));
    grid-auto-columns: minmax(0, 1fr);
    grid-gap: .7%;
    margin: 1px;
}

#gameboard, .GameBoardOverlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.BoardSquare {
    background-image: url("assets/grain.png");
    background-color: var(--jBlue);
    border-radius: 3pt;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0 solid var(--jGold);
}

.CategoryName {
    grid-row: 1;
}

.CategoryName > div {
    text-shadow: .08em .08em 0 #000000;
    font-family: "Helvetica Neue", "Trebuchet MS", sans-serif;
    font-size: inherit;
    font-weight: bold;
    font-stretch: condensed;
    padding: 1pt 4pt;
}

.ClueValue {
    font-family: "Swiss911", sans-serif;
    font-stretch: condensed;
    font-weight: bold;
    color: var(--jGold);
    font-size: clamp(15pt, 5.5vw, 55pt);
    padding: 5px max(10px, 5%);
    text-shadow: .04em .05em 0 #000000;
}

@media (hover: hover) {
    .SelectableClue:hover {
        background-color: var(--jBlue2);
        cursor: pointer;
    }

    .SelectableClue:active {
        background-color: var(--jBlue1);
    }
}

.PlayedClue {
    opacity: .5;
    cursor: default;
}

.GameBoardOverlay {
    background-image: url("assets/grain.png");
    background-color: var(--jBlue);
    background-repeat: repeat;

    display: flex;
    align-items: center;
    justify-content: center;
}

.CategoryOverlay {
    position: absolute;
    top: 2pt;
    left: 4pt;
    text-shadow: 1pt 1pt 2pt #0009;
    font-size: large;
}

#clueText {
    padding: 1em 1em;
    width: 100%;
    /*height: 100%;*/
    font-family: Korinna, sans-serif;
    font-size: min(50px, 6vw);
    text-align: center;
    text-shadow: 1px 1px 0 #000, 2px 2px 0 #000, 3px 3px 0 #000;
}

#ddTitle {
    font-family: JeofontHeavy, sans-serif;
    font-size: min(130px, 10vw);
    text-align: center;
    text-shadow: 1pt 1pt 0 #000, 2pt 2pt 0 #000, 3pt 3pt 0 #000;
    padding: 10pt
}

#answerReveal {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.GameBoardOverlay > button {
    position: absolute;
    top: 10px;
    left: 10px;
}

@media screen and (max-width: 800px) {
    .BoardSquare {
        border-radius: 2px;
    }

    * {
        border-color: yellow;
    }

    .ClueValue {
        font-size: clamp(15pt, 7vw, 50pt);
    }
}

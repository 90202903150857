* {
    box-sizing: border-box;
    user-select: none;
    cursor: default;
}

:root {
    height: 100vh;
    position: relative;
}

@supports (height: 100svh) {
    :root {
        height: 100svh;
    }
}

body {
    margin: 0;
    padding: 0;
    position: relative;
    height: 100%;
    /*min-height: calc(100% + env(safe-area-inset-top));*/
    background-image: url("assets/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    -webkit-touch-callout: none !important;
}

#root {
    height: 100%;
}

@font-face {
    font-family: "JeofontRegular";
    src: url("assets/Jeofont-Regular.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "JeofontHeavy";
    src: url("assets/Jeofont-Heavy.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Korinna";
    src: local("KorinnaRegular"),
    url("assets/Korinna-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Swiss911";
    src: url("assets/swiss-911-extra-compr9c22f.ttf") format("opentype");
    font-weight: bold;
}

@font-face {
    font-family: "Helvetica Neue";
    src: url("assets/HelveticaNeue-Condensed.otf") format("opentype");
    font-stretch: condensed;
}
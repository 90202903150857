* {
    /*border: 1px solid red;*/
    font-family: "Trebuchet MS", sans-serif;
    color: white;
    font-size: large;
}

:root {
    --jBlue: #060ce9;
    --jBlue1: #4747fc;
    --jBlue2: #6464e3;
    --jBlue3: #aec5eb;
    --jGold: #fcca46;
    --themeColor: #190046;

    --jBlueAlpha95: #060ce9f0;
    --jBlueAlpha88: #060ce9ea;

    --buttonDefault: #FFFFFF30;
    --buttonHover: #FFFFFF60;
    --buttonActive: #FFFFFF10;

    --backgroundColor: #00000095;
    --overlayColor: #00000045;
}

h1, h2, h3, h4, h5, h6 {
    font-family: JeofontHeavy, sans-serif;
    margin: 5px 0;
}

h1 {
    font-size: xx-large;
}

h2 {
    font-size: x-large;
}

h3 {
    font-size: large;
}

input[type=text] {
    user-select: text;
    cursor: default;
    text-shadow: none;
}

.App {
    height: 100%;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}

#header {
    width: 100%;
    padding: 6px 10px;
    z-index: 1000;
    gap: 10px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Title {
    text-shadow: 0 3px .3em #00000050;
}

.PCTitle {
    margin: 2pt 4pt;
}

.PhoneTitle {
    margin: 1pt 4pt;
    display: none;
}

#titleSmall {
    text-shadow: 0 3px .3em #000000b0;
    margin: 0 2%;
}

#header > h2 {
    font-family: "JeofontRegular", sans-serif;
}

#content {
    flex: 1 0 auto;
    padding: 8pt;
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    justify-items: flex-start;
    align-items: flex-start;
    align-content: center;
    flex-wrap: wrap;
    gap: 8pt;
    min-height: 200px;

}

#loading {
    flex: 1 1 auto;
    text-align: center;
}

#mainScreen {
    flex: 1 1 auto;
    min-width: 200px;
    max-width: 1000px;
    border-radius: 6pt;
    position: relative;
    padding: min(.5%, 5pt);
    /*overflow: scroll;*/
    /*max-height: 75vh;*/
    min-height: 200px;
}

#mainScreenContentContainer {
    padding-top: 66%;
    position: relative;
    overflow: hidden;
    border-radius: 3pt;
}

#sideScreen {
    width: 300px;
    gap: 6pt;
    display: flex;
    flex-direction: column;
    padding: 0 0 10px;
}

.SideScreenContent {
    /*min-height: 10px;*/
    border-radius: 6pt;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*margin-bottom: 10px;*/
    max-width: 320px;
}

#mainScreen, .SideScreenContent {
    background-color: var(--backgroundColor);
    --blurRadius: 20px;
    backdrop-filter: blur(var(--blurRadius));
    -webkit-backdrop-filter: blur(var(--blurRadius));
}

@media screen and (max-width: 800px) {
    #content {
        padding: 0;
        /*align-content: flex-start;*/
    }

    #mainScreen {
        min-width: unset;
        width: 100%;
        border-radius: 4pt;
        margin: 0;
    }

    #sideScreen {
        width: 100%;
        padding: 0 5px 10px;
    }

    .SideScreenContent {
        max-width: 100%;
    }

    .PCTitle {
        display: none;
    }

    .PhoneTitle {
        display: unset;
    }
}

.SelectedButton {
    border: 2px solid white;
}

button {
    border: none;
    border-radius: 4px;
    padding: 6px 8px;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    user-select: none;
    text-shadow: 0 0 1px var(--backgroundColor);
    font-size: medium;
}

button:not(:disabled) {
    cursor: pointer;
    background-color: var(--buttonDefault);
}

@media (hover: hover) {
    button:not(:disabled):hover {
        background-color: var(--buttonHover);
        box-shadow: 0 0 5px #00000030;
    }
}

button:not(:disabled):active {
    background-color: var(--buttonActive);
    box-shadow: none;
}
